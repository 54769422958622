
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background {
  background: rgba(19, 187, 86, 0.12);
  overflow:hidden !important;
  width: 100vw;
  height: 100vh;


}

.backgroundImage{
  background-image: url("images/background.svg");
  background-size:cover;
  background-position:bottom;
  height: 55vh;
}

.backgroundImageSmall{
  background-image: url("images/background.svg");
  background-size:cover;
  background-position:bottom;
  height: 30vh;
}
.backgroundImageDashboardClient{
  background-image: url("images/backgroundDashboardClient.svg");
  background-size:cover;
  background-position:bottom;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 21vh;
}

.backgroundImageDashboardManager{
  background-image: url("images/backgroundDashboardManager.svg");
  background-size:cover;
  background-position:bottom;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 19vh;
}

.logo{
  background-image: url("images/logo.svg");
  z-index: 99;
  width:50px !important;
  height: 36px !important;
  cursor: pointer;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
}

.btnDashboardClient {
  border-radius: 0.9375rem;
  border: none;
  z-index: 99;
  width: 6.5rem ;
  height: 6.5rem ;
  cursor: pointer;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 1.875rem;
}


.btnDashboardManager {
  border-radius: 0.9375rem;
  border: none;
  z-index: 99;
  width: 6.5rem ;
  height: 6.5rem ;
  cursor: pointer;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 1rem;
}

@media (max-width: 950px) {
  .btnDashboardClient {
    width: 70px;
    height: 70px; 
  }
  .btnDashboardManager {
    width: 70px;
    height: 70px; 
    margin:0.8rem;
  }
}

@media (max-width: 750px) {
  .btnDashboardClient {
    width: 70px;
    height: 70px; 
  }
  .btnDashboardManager {
    width: 70px;
    height: 70px; 
    margin:0.3rem;
  }
}
.topcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.125rem; 
  flex-direction: column;
}

.leftcenter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 1.375rem;
  left: 9.375rem; 
}

.titleDashboardClient {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.5rem, 2.5vw, 2.125rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
}


.subtitleDashboardClient {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.125rem, 2vw, 1.625rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-shrink: 0;
}

@media (max-width: 950px) {
  .leftcenter {
    left: 5rem;
    top: 3rem;  
  }

  .titleDashboardClient {
    font-size: 1.25rem;
    text-align: left;   
  }

  .subtitleDashboardClient {
    font-size: 1rem;
    text-align: left;   
  }
}

@media (max-width: 650px) {
  .titleDashboardClient {
      font-size: 1rem;
      text-align: left;
  }
  .subtitleDashboardClient {
    font-size: 0.75rem;
    text-align: left;   
  }
  .leftcenter {
    left: 5rem;
    top: 2.5rem;  
  }
}

.title {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.75rem, 3vw, 2.5rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  /* width: 20.625rem; */
  height: 38vh;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
}

.titlesmall {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.75rem, 3vw, 2.5rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 18.625rem;
  height: 13vh;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
}


.bottomcenter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bottomcenterregister {
  position: fixed;
  left: 0;
  right: 0;
  top: 32vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.bodytext {
  color: #0F1111;
  width: 19.125rem;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.greenbtn {
  width: 20.625rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  border-width: 0px;
  margin-bottom: 0.9375rem;
  margin-top: 2.5rem;
  cursor: pointer;
}


.greenbtn:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.40) 100%), #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
}

.blackbtn {
  width: 20.625rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #0F1111;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  border-width: 0px;
  margin-bottom: 0.9375rem;
  margin-top: 2.5rem;
  cursor: pointer;
}


.blackbtn:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.40) 100%), #0F1111;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
}

.smallgreenbtn {
  width: 14.375rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  border-width: 0px;
  margin-bottom: 0.9375rem;
  margin-top: 2.5rem;
  cursor: pointer;
}

.smallgreenbtn:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.40) 100%), #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
}

.whitebtn {
  width: 20.625rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  border-width: 0px;
  cursor: pointer;
}


.whitebtn:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}


.columnbtns{
  flex-direction: column;
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.input-container {
  position: relative;
  width: 20.625rem;
  height: 2.5rem;
  margin-top: 0.9375rem;
}

.input-container-gender {
  position: relative;
  width: 20.625rem;
  height: 1.875rem;
  margin-top: 0;
  margin-bottom: 2.5rem !important;
}

.input-container input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #13BB56;
  color: rgba(15, 17, 17, 0.90);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.035rem;
  outline: none;
}

.input-container label {
  position: absolute;
  left: 0.625rem;
  transform: translateY(-50%);
  color: rgba(15, 17, 17, 0.40);
  pointer-events: none;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.035rem;
  transition: all 0.3s ease;
}


.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
  bottom: 0.3125rem;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  color: rgba(15, 17, 17, 0.40);
}
.input-container2 {
  position: relative;
  width: 90%;
  height: 2.5rem;
  margin-top: 0.9375rem;
}



.input-container2 input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #13BB56;
  color: rgba(15, 17, 17, 0.90);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.035rem;
  outline: none;
}

.input-container2 label {
  position: absolute;
  left: 0.625rem;
  transform: translateY(-50%);
  color: rgba(15, 17, 17, 0.40);
  pointer-events: none;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.035rem;
  transition: all 0.3s ease;
}


.input-container2 input:focus + label,
.input-container2 input:not(:placeholder-shown) + label {
  bottom: 0.3125rem;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  color: rgba(15, 17, 17, 0.40);
}

.input-container-gender input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #13BB56;
  color: rgba(15, 17, 17, 0.90);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.035rem;
  outline: none;
}

.input-container-gender label {
  position: absolute;
  left: 0.625rem;
  transform: translateY(-50%);
  color: rgba(15, 17, 17, 0.40);
  pointer-events: none;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.035rem;
  transition: all 0.3s ease;
}

.input-container-gender input:focus + label,
.input-container-gender input:not(:placeholder-shown) + label {
  bottom: 0.3125rem;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  color: rgba(15, 17, 17, 0.40);
}

.input-containerblack {
  position: relative;
  width: 20.625rem;
  height: 2.5rem;
  margin-top: 0.9375rem;
}

.input-containerblack input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #0F1111;
  color: rgba(15, 17, 17, 0.90);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.035rem;
  outline: none;
}

.input-containerblack label {
  position: absolute;
  left: 0.625rem;
  transform: translateY(-50%);
  color: rgba(15, 17, 17, 0.40);
  pointer-events: none;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.035rem;
  transition: all 0.3s ease;
}

.input-containerblack input:focus + label,
.input-containerblack input:not(:placeholder-shown) + label {
  bottom: 0.3125rem;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  color: rgba(15, 17, 17, 0.40);
}


.input.has-text + label {
  display: none;
}

.label.visible {
  display: block;
}

.toggle-password {
  background-color: transparent;
  border: none;
  color: rgba(15, 17, 17, 0.40);
  position: absolute;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 100%;
}

.topleftrel {
  position: relative;
  top: 1.5rem;
  right: 11.25rem;
  z-index: 1;
}

.topleftreg {
  position: relative;
  top: 0.5rem;
  right: 11.25rem;
  z-index: 1;
  margin-left: 5vh;
}

.topleftrelregister {
  position: absolute;
  right: 70%;
  bottom: 35vh;
  z-index: 1;
}

@media (max-height: 950px ) {
  .backgroundImage{
    background-image: url("images/background.svg");
    background-size:cover;
    background-position:bottom;   
    height: 45vh;
  }
  .title{
    height: 28vh;
  }
  .topleftrel{
    margin-left: 5vh;
    height: 20vh !important;
    z-index: 1;
  }
    
    }

  @media (max-height: 700px ) {
    .backgroundImage{
      background-image: url("images/background.svg");
      background-size:cover;
      background-position:bottom;
      height: 35vh;
    }
    
    .title{
      height: 15vh;
    }

    .topleftrel{
      margin-left: 5vh;
      height: 20vh !important;
      z-index: 1;
    }
  }
  .textlinklogin2 {
    color: #343434;
    text-align: center;
    font-family: 'Montserrat';
    font-size: clamp(0.5rem, 1.5vw, 0.625rem);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  
  .textlinklogin2:hover {
    color: #343434;
  }
  
  .searchicon {
    margin-left: 0.625rem;
    transform: translateY(-140%);
  }
  
  .row {
    display: flex;
    padding: 0.625rem 0.625rem;
    gap: 0.625rem;
  }
  
  .cardStation {
    border-radius: 0.5rem;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
    height: 1.8125rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .selectedCardStation {
    border-radius: 0.5rem;
    background-color: #13BB56;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
    height: 1.8125rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .selectedTextCard {
    color: #FFF;
    font-family: 'Montserrat' !important;
    font-size: clamp(0.625rem, 1.5vw, 0.75rem) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding-top: 0.5rem;
    width: max-content;
  }
  
  .textCard {
    color: #13BB56;
    font-family: 'Montserrat' !important;
    font-size: clamp(0.625rem, 1.5vw, 0.75rem) !important; 
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding-top: 0.5rem;
    width: max-content;
  }
  
  
  .scrollContainer {
    overflow-x: scroll;
    max-width: 20.625rem;
    display: flex;
    height: 4.375rem;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  
  .preflabel {
    position: relative;
    width: 20.625rem;
    color: rgba(15, 17, 17, 0.40);
    pointer-events: none;
    font-family: 'Montserrat';
    font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.035rem;
    transition: all 0.3s ease;
  }
  
  
  .cardPref {
    border-radius: 0.5rem;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    width: 9.8125rem;
  }
  
  .selectedCardPref {
    border-radius: 0.5rem;
    background-color: #13BB56;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    width: 9.8125rem;
  }
  
  .scrollContainerActivities {
    overflow-x: scroll;
    max-width: 25.625rem;
    display: flex;
    height: 6.6875rem;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 0.3125rem;
  }

  .scrollContainerActivities2 {
    overflow-x: scroll;
    max-width: 25.625rem;
    display: flex;
    height: 35.6875rem;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .scrollContainerActivities3 {
    overflow-x: scroll;
    max-width: 25.625rem;
    display: flex;
    height: 10rem;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .scrollContainerPref {
    overflow-x: scroll;
    max-width: 25.625rem;
    display: flex;
    height: 35.6875rem;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  
  @media (max-width: 768px) {
    .scrollContainerPref {
      max-width: 90%; /* Largura ajustada para 90% da tela no mobile */
      margin: 0 auto; /* Centraliza o container horizontalmente */
    }
  }
  .row {
    display: flex;
    padding: 0.125rem 0.125rem;
    gap: 0.625rem;
  }
  /* .rowActivities {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 0.75rem;
    gap: 0.625rem 0.625rem;
  } */
  
  .registergreenbtn {
    width: 20.625rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: #13BB56;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
    letter-spacing: clamp(0.6px, 0.8px, 0.8px);
    text-transform: uppercase;
    border-width: 0px;
    margin-bottom: 0.9375rem;
    margin-top: 0.625rem;
    cursor: pointer;
  }
  
  
  .registergreenbtn:hover {
    background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.40) 100%), #13BB56;
    box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
  }
  
  .header {
    background-color: #FFFFFF;
    height: 3.75rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 105;
    border-radius: 0 0 3% 3%;
    transition: left 0.5s ease;
  }
  
  .headerTransparent {
    background-color: transparent;
    height: 3.75rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 105;
    border-radius: 0 0 3% 3%;
    transition: left 0.5s ease;
  }
  
  .headerImage {
    background-image: url("images/headerClient.jpg");
    background-size: cover;
    background-position: center center;
    height: 3.75rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 105;
    border-radius: 0 0 3% 3%;
    transition: left 0.5s ease;
  }
  
  .headerRight {
    position: fixed;
    right: 0px;
    transition: right 0.5s ease;
  }
  
  .header-toggle {
    background-color: #7F7F7F;
    width: 100%;
    height: 3.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .textHeader {
    color: #0F1111;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
    font-style: normal;
    font-weight: 600;
    line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  }
  
  .subtextHeader {
    color: rgba(15, 17, 17, 0.60);
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.625rem, 1.5vw, 0.75rem);
    font-style: normal;
    font-weight: 500;
    line-height: clamp(0.625rem, 1.5vw, 0.75rem); 
    margin-top: 0.3125rem;
  }
  
  .textHeaderWhite {
    color: #FFF;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
    font-style: normal;
    font-weight: 600;
    line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  }
  
  .subtextHeaderWhite {
    color: #FFF;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.625rem, 1.5vw, 0.75rem); 
    font-style: normal;
    font-weight: 500;
    line-height: clamp(0.625rem, 1.5vw, 0.75rem); 
    margin-top: 0.3125rem;
  }
  
  
  .navbar {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0%;
    width: 3.75rem;
    top: 0%;
    bottom: 0%;
    background: #13BB56;
    border-radius: 0px 0.9375rem 0.9375rem 0px;
    transition: width 0.5s ease;
    overflow: hidden;
    z-index: 106;
  }
  

  .navbarclient{
    position: absolute;
    display: flex;
    align-items: center; 
    left: 0%;
    width: 3.75rem;
    top: 0%;
    bottom: 0%;
    background: #13BB56;
    border-radius: 0px 15px 15px 0px;
    transition: width 0.5s ease;
    overflow: hidden;
    z-index: 106;
  }
  .logonavclient{
    position: absolute;
    width:40px !important;
    height: 28px !important;
  left: 11px !important;
  top: 11px !important;
  cursor: pointer;
  background-image: url("images/logonav.svg");
  background-size: fill;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .logonav{
    position: absolute;
    width:40px !important;
    height: 28px !important;
  left: 11px !important;
  top: 11px !important;
  cursor: pointer;
  background-image: url("images/logonav.svg");
  background-size: fill;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .bottomNavigationAction {
    display: inline-flex;
    align-items:center;
    justify-content: flex-start;
    transition: none;
    margin-top: 15% !important;
    margin-bottom: 15%!important;
    flex-direction: row;
    box-shadow: none !important; 
    border: none !important; 
    background-color: transparent !important; 
  }
  .bottomNavigationAction:active {
    opacity: 0.5;
    transform: scale(0.95); 
  }

@media (max-width: 768px) {
  .navbarclient {
    display:flex;
    align-items: center !important;
    flex-direction: row;
    left: 0;
    right: 0;
    top: auto; 
    bottom: 0; 
    height: 3.75rem; 
    width: 100%; 
    border-radius: 15px 15px 0px 0px; 
  }

  .navItems {
    flex-direction: row; 
    justify-content: space-around; 
    align-items: center; 
    width: 100%;
    display:flex;
  }

  .bottomNavigationAction {
    display: inline-flex;
    align-items:center;
    justify-content: flex-start;
    transition: none;
    margin-top: 0.5% !important;
    margin-bottom: 0.5%!important;
    flex-direction: row;
    box-shadow: none !important; 
    border: none !important; 
    background-color: transparent !important;
  }
  .bottomNavigationAction:active {
    opacity: 0.5;
    transform: scale(0.95); 
  }
}
.compactNavigationAction {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px !important; 
  padding: 0 !important;    
  transition: none;
  flex-direction: row;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.compactNavigationAction:active {
  opacity: 0.5;
  transform: scale(0.95);
}
  .toprow {
    flex-direction: row;
    width: 100%;
    margin-top: 5rem !important;
    margin-left: 5rem !important; 
    display: flex;
  }

  .rowstats {
    flex-direction: row;
    width: 100%;
    margin-top: 5rem !important;
    margin-bottom: 1rem !important;
    margin-left: 3.45rem !important; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  
  .rowstats2 {
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !important;
    margin-left: 3.45rem !important; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .rowstats {
      flex-direction: column; /* Muda para coluna em dispositivos móveis */
      margin-left: 0rem !important; /* Ajusta o espaçamento */
      margin-bottom: 0rem !important;

    }
    .rowstats2 {
      flex-direction: column; /* Muda para coluna em dispositivos móveis */
      margin-left: 0rem !important; /* Ajusta o espaçamento */
      margin-top: 0rem !important;  /* Ajuste do espaçamento superior */
      margin-bottom: 0rem !important;

    }
  }
  
  .toprow2 {
    flex-direction: row;
    width: 82rem;
    margin-top: 1.5rem !important; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3.75rem !important; 
  }
  
  
  .greenCard{
    border-radius: 15px;
background: #13BB56;
box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
width: 19.6875rem;
height: 11rem;
flex-shrink: 0;

  }
  .redCard{
    border-radius: 15px;
background: #ED4F2C;
box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
width: 19.6875rem;
height: 11rem;
flex-shrink: 0;
  }

  .whiteCard{
    border-radius: 15px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
width: 19.6875rem;
height: 11rem;
flex-shrink: 0;
margin-inline: 1.25rem;
  }

  @media (max-width: 1200px) {
    .greenCard, .redCard, .whiteCard {
      width: 25%; /* Alterar para porcentagem em telas menores */
    }
    .dashboardManagerEvents {
  
      width: 90% !important; 
   
    }
  }
  .whiteBigCard {
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
    flex-grow: 1;
    max-width: calc(100% - 47.25rem);
    height: 11rem;
  }
  .whiteBigCard2 {
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
    flex-grow: 1;
    max-width: calc(100% - 47.25rem);
    height: 11rem;
  }

  .whiteStatsCard {
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
   width:65.75rem;
    height: 11rem;
  }
  @media (max-width: 1200px) {
    .whiteStatsCard {
      width: 90%; 
      margin-right: 1rem;
    }
  }
  
  
  .whiteTextGraph {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: clamp(1rem, 2vw, 1.275rem);
    font-weight: 600;
    line-height: normal;
    padding-left: 0.9375rem;
    padding-top: 0.9375rem;
    align-self: flex-start;
  }
  
  .blackTextGraph {
    color: #0F1111;
    font-family: 'Montserrat';
    font-size: clamp(1rem, 2vw, 1.275rem); 
    font-weight: 600;
    line-height: normal;
    padding-left: 0.9375rem;
    padding-top: 0.9375rem;
    align-self: flex-start;
  }
  
  .graphTopRow {
    display: flex;
    align-items: flex-start;
    margin-bottom: auto;
    flex-direction: row;
    height: min-content;
    justify-content: space-between;
  }

  .graphRowStats {
    display: flex;
    align-items: center;
    flex-direction: row;
    width:200px;
    justify-content: center;
  }
  
  .selectMonthGrey {
    color: rgba(255, 255, 255, 0.60);
    background-color: transparent;
    border: none;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    font-weight: 600;
    line-height: clamp(1.125rem, 2vw, 1.375rem); 
    margin-top: 0.9375rem;
    margin-right: 0.9375rem;
  }
  
  .selectMonthBlack {
    color: rgba(15, 17, 17, 0.60);
    background-color: transparent;
    border: none;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-weight: 600;
    line-height: clamp(1.125rem, 2vw, 1.375rem); 
    margin-top: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .selectMonthBlackHorizontal {
    color: rgba(15, 17, 17, 0.60);
    background-color: transparent;
    border: none;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-weight: 600;
    line-height: clamp(1.125rem, 2vw, 1.375rem); 
    margin-top: 0.9375rem;
    margin-right: 0.9375rem;
    position: absolute;
    top:5px;
    right:10px;
    display: flex;
  
  }
  
  .whiteValue {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: clamp(2rem, 3vw, 2.75rem); 
    font-weight: 600;
  }
  
  .blackValue {
    color: #0F1111;
    font-family: 'Montserrat';
    font-size: clamp(2rem, 3vw, 2.75rem); 
    font-weight: 600;
  }
  
  
  .graphBottomRow {
    display: flex;
    align-items: center;
   
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 0.9375rem;
  }
  
  .percentageGraphWhite {
    border-radius: 0.5rem;
    background: #FFF;
    width: 4.375rem;
    height: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 0.9375rem;
  }
  
  .percentageGraphRed {
    border-radius: 0.5rem;
    background: #ED4F2C;
    width: 4.375rem;
    height: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 0.9375rem;
  
  }
  
  .percentageGraphGreen {
    border-radius: 0.5rem;
    background: #13BB56;
    width: 4.375rem;
    height: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 0.9375rem;
   
  }
  
  .percentageTextGreen {
    color: #13BB56;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  
  .arrowTextGreen {
    color: #13BB56;
    font-family: 'Montserrat';
    font-size: clamp(1.5rem, 2.5vw, 1.8125rem); 
    font-weight: 500;
    line-height: clamp(1rem, 1.5vw, 1.25rem); 
    text-align: center;
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  
  .percentageTextWhite {
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-weight: 700;
    line-height: normal;
  }
  
  .arrowTextWhite {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: clamp(1.5rem, 2.5vw, 1.8125rem); 
    font-weight: 500;
    line-height: clamp(1rem, 1.5vw, 1.25rem); 
    text-align: center;
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  
  .percentageTextRed {
    color: #ED4F2C;
    text-align: right;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
    font-weight: 700;
    line-height: normal;
  }
  
  .arrowTextRed {
    color: #ED4F2C;
    font-family: 'Montserrat';
    font-size: clamp(1.5rem, 2.5vw, 1.8125rem); 
    font-weight: 500;
    line-height: clamp(1rem, 1.5vw, 1.25rem); 
    text-align: center;
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  
  
  .btnsrow {
    flex-direction: row;
    margin-top: 0;
    margin-left: 4.375rem;
    display: flex;
 
  }
  .btnsrowadmin {
    flex-direction: row;
    margin-top: 0;
    margin-left: 4.275rem;
    margin-right: 3rem;
    display: flex;
 
  }
  @media (max-width: 768px) {
    .toprow2 {
      flex-direction: column;
      width: 100%;
      margin: 0 0 !important;
      align-items: center;
      margin-left: 0 !important;
    }
  
    .greenCard,
    .redCard,
    .whiteCard {
      width: 90%; /* Ajuste para ocupar quase toda a largura */
      height: auto;
      margin-bottom: 1rem; /* Espaçamento entre cards */
    }
  
    .whiteBigCard {
      width: 90%;
      height: auto;
      max-width: 100%;
      margin: 1rem 0;
   
    }
    .whiteBigCard2 {
      width: 90%;
      height: auto;
      max-width: 100%;
      margin: 1rem 0;
      display:block !important;
    }
  
    .whiteStatsCard {
      width: 90%;
      height: auto;
      margin: 1rem 0;
    }
  
    .whiteTextGraph,
    .blackTextGraph {
      font-size: 1rem; /* Diminuir tamanho do texto */
      padding: 0.5rem;
    }
  
    .graphTopRow,
    .graphBottomRow {

      align-items: flex-end;
    }
  
    .graphRowStats {
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
    }
  
    .selectMonthGrey,
    .selectMonthBlack,
    .selectMonthBlackHorizontal {
      font-size: 0.875rem;
      text-align: right;
      margin: 0.5rem ;
      position: static;
    }
  
    .percentageGraphWhite,
    .percentageGraphRed,
    .percentageGraphGreen {
      /* width: 90%; */
      height: 2rem; /* Altura maior para botões em mobile */
      margin: 0.5rem;

    }
  
    .percentageTextGreen,
    .percentageTextWhite,
    .percentageTextRed,
    .arrowTextGreen,
    .arrowTextWhite,
    .arrowTextRed {
      font-size: 1rem; /* Reduzir tamanho para mobile */
      margin: 0;
    }
  
    .btnsrow {
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
    }
    .dashboardManagerEvents {
      flex-direction: column !important; 
      width: 90% !important; 
      margin-left: 0 !important; 
      padding: 0 0rem !important;
      margin-inline: 1rem !important;
    }
  }
  
  .btnsrow2 {
    flex-direction: row;
    margin-top: 0.625rem;
    margin-left: 4.375rem; 
    display: flex;
  }
  .btnsrow2admin {
    flex-direction: row;
    margin-top: 0.625rem;
    margin-left: 4.375rem;
    margin-right: 3rem;
    display: flex;
  }
  
  .eventsrow {
    flex-direction: row;
    margin-top: 0;
    margin-left: 4.375rem; 
    display: flex;
  }
  
  .dashboardBtn {
    position: relative;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
    width: 16rem;
    height: 11.4375rem; 
    flex-shrink: 0;
    border: 0;
    margin-inline: 0.25rem; 
    cursor: pointer;
   
    justify-content: center;
    display: flex;
  }

  .dashboardBtnRender {
    position: relative;
    background: transparent;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  
  .badgeContainer {
    position: absolute;
    top: 8px; 
    right: 18px; 
  }
  
.dashboardBtn:hover{
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
}

.dashboardDown{
  flex-direction: column;


}
.dashboardBase{
  display: flex;
   flex-direction: row;
    height: 100% ;
    width:100%
}

.dashboardManagerEvents{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 75rem;
  margin-left: 3.75rem; 
}

.blackTextTitleDashboard {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
}

.blackTextTitleDashboardEvents {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 5rem; 
  align-self: flex-start;
}

.dashboardBtnText {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2.5vw, 1.375rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.centerbtn {
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.whiteVerticalCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
  flex-grow: 1;
  max-width: calc(90% - 26.25rem);
  max-height: 36.5rem; 
  flex-direction: column;
  display: flex;
  margin-left: 0.625rem; 
}

.whiteHorizontalCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
  flex-grow: 1;
  max-width: 65.75rem;
  height: 16.5rem; 
  flex-direction: row;
  display: flex;
  position: relative;
  margin-left: 0.625rem; 
}

@media (max-width: 1200px) {
  .whiteHorizontalCard {
    max-width: 90%; 
    margin-right: 1.75rem;
  }
}
.whiteHorizontalCard div {
  flex-direction: column;

}

.whiteHorizontalCard canvas {
  height: 180px;
  width: 100%; 
  max-width: 300px; 
}

@media (max-width: 1200px) {
  .whiteHorizontalCard {
    max-width: 90%; 
    height: auto; 
  }

  .whiteHorizontalCard canvas {
    height: auto; 
    width: 100%; 
  }
}


.whiteHorizontalCard2 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
  flex-grow: 1;
  max-width: 65.75rem;
  height: 16.5rem; 
  flex-direction: row;
  display: flex;
  position: relative;
  margin-left: 0.625rem; 
}

@media (max-width: 1200px) {
  .whiteHorizontalCard2 {
    max-width: 100%; 
    margin-right: 0.8rem;
  }
}
.whiteHorizontalCard2 div {
  flex-direction: column;

}

.whiteHorizontalCard2 canvas {
  height: 180px;
  width: 100%; 
  max-width: 300px; 
}

@media (max-width: 1200px) {
  .whiteHorizontalCard2 {
    max-width: 90%; 
    height: auto; 
  }

  .whiteHorizontalCard2 canvas {
    height: auto; 
    width: 100%; 
  }
}

.whiteSmallCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
  flex-grow: 1; 
  max-width: 35%;
  height: 9.0625rem; 
  margin-inline: 0.3125rem; 
  flex-direction: row;
  display: flex;
}


@media (max-width: 1550px) {
  .dashboardBtn {
    width: 20%; 
  }
  .dashboardBtnText {
    color: #044535;
    text-align: center;
    font-family: 'Montserrat';
    font-size: clamp(1rem, 2vw, 1.125rem); 
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
}

@media (max-width: 1320px) {
  .dashboardBtn {
    width: 20%;
  }
  .dashboardBtnText {
    color: #044535;
    text-align: center;
    font-family: 'Montserrat';
    font-size: clamp(0.875rem, 2vw, 1rem);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
}

@media (max-width: 1200px) {
  .dashboardBtn {
    width: 20%;
  }
}

@media (max-width: 1050px) {
  .dashboardBtn {
    width: 20%;
  }
}
@media (max-height: 950px) {
  .btnsrow{
   height:16vh
  }
  .btnsrow2{
  height:16vh;
  margin-top:1.55rem
  }
  .dashboardBtn{
  height: 12vh !important;
  }
  .whiteVerticalCard{ 
 height:59vh;
 max-height:36.25rem !important
  }
}

@media (max-height: 850px) {
  .btnsrow{
   height:13vh
  }
  .btnsrow2{
  height:14vh
  }
  .dashboardBtn{
  height: 14vh !important;
  }
  .whiteVerticalCard{ 
 display:none
  }
  .eventsrow{
    width:calc(100% - 4.375rem);

   
    }
    .dashboardDown{
      width:100%
    }
    .whiteSmallCard{
    max-width: 31.25rem;
    }
    .dashboardBase{
      display:unset
   
    }
}

@media (max-height: 700px) {
  .toprow{
  display:none
  }
  .blackTextTitleDashboard{
    margin-top: 1rem;
  }
}

@media (max-height: 800px) {
  .dashboardBtn {
    height: 5rem !important;
  }
  .btnsrow{
    height:10vh
   }
   .btnsrow2{
   height:12vh
   }
}



@media (max-width: 1550px) {
  .whiteVerticalCard {
    display: none;
  }
  .eventsrow{
    width:calc(100% - 4.375rem)
    }
    .dashboardDown{
      width:100%
    }
    .btnsrow{
      width:calc(100% - 8.0rem)
    }
    .btnsrow2{
      width:calc(100% - 8.0rem)
    }
    .whiteSmallCard{
    max-width: 31.25rem;
    }
    .dashboardBase{
      display:unset
   
    }
}
@media (max-width: 1200px) {
  .whiteBigCard2 {
    display: none;
  }
  .toprow {
    flex-direction: row;
    width: 100%;
    margin-top: 5rem !important;
    margin-left: 2rem !important; 
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 900px) {
  .whiteBigCard {
    display: none;
  }
  .toprow {
    flex-direction: row;
    width: 100%;
    margin-top: 5rem !important;
    margin-left: 2rem !important; 
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 758px) {
  .btnsrow{
  
    width:55%;
    height:10vh
  }
  .btnsrow2{
    width:55%;
    height:10vh
  }
  .toprow {
    flex-direction:column;
    width: 100%;
    margin-top: 5rem !important;
    margin-left: 2rem !important; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    
  }
  .dashboardBtn {
    position: relative;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0.1rem 0.2rem 0px rgba(30, 30, 30, 0.15) !important;
    height: 4rem !important; 
    flex-shrink: 0;
    border: 0;
    margin-inline: 1%; 
    cursor: pointer;
    width: 18% !important;
   
    justify-content: center;
    display: flex;
  }
  .dashboardBtnText{
    display: none;
  }
}


.imgDashboardCard{
  height: 100%;
  width:50%;
  border-radius: 15px;
  flex-shrink: 0;
  object-fit: cover;
}

.whiteSmallCardDay {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.375rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 1.25; 
}

.whiteSmallCardMonth {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 1.75vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 1.375; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.whiteSmallCardEvent {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.375rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin-top: 1rem; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
}

.whiteSmallCardCity {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 1.75vw, 1.25rem);
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; 
  max-width: 90%;
  padding-top: 1rem; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width:1300px) { 
  .whiteSmallCardDay {
    font-size: clamp(0.875rem, 1.5vw, 1rem); 
  }
  
  .whiteSmallCardMonth {
    font-size: clamp(0.625rem, 1.25vw, 0.75rem); 
  }
  
  .whiteSmallCardEvent {
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
  
  .whiteSmallCardCity {
    font-size: clamp(0.75rem, 1.25vw, 0.875rem);
  }
  
  .blackTextGraph {
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
  
  .whiteTextGraph {
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
  
}

.topLeftBack {
  position: absolute;
  left: 6.25rem; 
  top: 6.25rem; 
  display: flex;
}

.topLeftBackTrip {
  position: absolute;
  left: 10.25rem; 
  top: 12.25rem; 
  display: flex;
}

.topLeftBackSmall {
  position: absolute;
  left: 13.7%; 
  top: 6.75rem; 
  display: flex;
}

.greenTitle {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2.5vw, 1.375rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 3rem; 
  margin-left: 3.75rem;
  display: flex;
}


@media (max-height:700px) { 
  .topLeftBack {
    top: 5.9375rem; 
  }
  .greenTitle {
    padding-top: 3.625rem; 
  }
}

@media (max-width:768px) { 
  .topLeftBack {
    top: 4.375rem; 
    left:1rem;
  }

}

.centerpage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.centerpagescroll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  
}

.centerpagescroll::-webkit-scrollbar {
  display: none;
}

.tripscroll{
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.whiteBoard {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
  width: 92%;
  height: 78vh; 
  flex-shrink: 0;
  margin-top: 2.5rem; 
  margin-left: 3.75rem;
  overflow: hidden;
}

.scrollableContent {
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.scrollableContentEvent {
  overflow-y: auto;
  height: 87%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.helpText {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  margin-inline: clamp(15%, 20%, 20%); 
}

.helpTitle {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2.5vw, 1.375rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin-inline: clamp(15%, 20%, 20%); 
}


.search {
  background-color: #fff;
  border-radius: 0.625rem; 
  height: 2.5rem; 
  width: 0;
  transition: width 0.3s ease;
  position: absolute;
  top: 0.0625rem;
  left: -0.625rem; 
  border:none
}

.searchButton {
  height: 1.875rem !important; 
  width: 1.875rem !important; 
  background-image: url('images/tableIcons/search.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.25rem; 
  cursor:pointer;
}

.searchButton:hover {
  transform: scale(1.1);
  background-size: 110% !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important; 
}

.addButton{
  height: 30px !important;
  width: 100px !important;
  /* background-image: url('images/tableIcons/add.svg') !important; */
  background-color: #044535;
background-size: fill;
background-repeat: no-repeat;
background-position: center ;
border-radius: 4px;
cursor:pointer;
margin-left: 10px;
color:white;
text-align: center;
align-items: center;
justify-content: center;
display: flex;
  font-family: 'Montserrat';
  font-size: clamp(0.5rem, 1.5vw, 0.8rem); 
  font-style: normal;
  font-weight: 600;
}
.addButton:hover {
  transform: scale(1.1);
  background-size: 110% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}
.grid {
  margin-inline: 2.5rem; 
  background-color: transparent !important;

}
@media (max-width: 1000px) {
  .grid {
    margin-inline: 1rem; 
  }
}

.MuiDataGrid-virtualScrollerContent {
  background-color: transparent;
}

.MuiDataGrid-row {
  height: 1.25rem !important; 
}

.MuiDataGrid-main {
  margin-inline: 3.75rem; 
}

.MuiDataGrid-footerContainer {
  margin-inline: 3.75rem; 
}

.MuiDataGrid-root {
  border-color: transparent !important;
  background-color: transparent !important;
  max-height: 40.625rem !important; 
  display: none;
}

.MuiDataGrid-columnHeaderTitle {
  color: #044535;
  font-family: 'Montserrat';
  font-size: clamp(0.9rem, 1.5vw, 1.125rem);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 1.5vw, 1.375rem); 
}

.MuiDataGrid-cell {
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 1.25vw, 1rem); 
  font-weight: 500;
  color: #0F1111;
}

.MuiDataGrid-row.Mui-selected {
  background-color: #e4f7ec !important;
}

.Mui-checked {
  color: #044535 !important;
}

.MuiDataGrid-footerContainer {
  border: 0 !important;
  justify-content: center !important;
}

.MuiTablePagination-displayedRows {
  font-family: 'Montserrat' !important;
  font-size: clamp(0.625rem, 1.5vw, 0.75rem) !important;
  color: rgba(15, 17, 17, 0.60) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}


.whiteInfoCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height:14.5rem;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  position: relative;
}
.whiteInfoCard3 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height:14.5rem;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  position: relative;
}
.whiteInfoCardClient {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height:14.5rem;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  position: relative;
}
.whiteInfoCard2 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height:14.5rem;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  position: relative;
}

.whiteInfoCardSmall {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 75%;
  height: 25vh;
  flex-shrink: 0;
  margin-top: 1.25rem;
  margin-left: 3.75rem; 
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.whiteEditInfoCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 48%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem; 
}
.whiteEditInfoCardEvaluation {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 38%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-bottom: 1.25rem; 
  margin-left: 3.75rem; 
}


.whiteEditInfoCard2 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 48%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem; 
}
.whiteBigInfoCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 76%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem; 
}

.whiteEditInfoCardSmall {
  border-radius: 0.9375rem;
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 75%;
  height: 50%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 3.75rem; 
}

.greenTitleClientData {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.125rem, 2.5vw, 1.375rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5.5rem;
  display: flex;
}

.greenTitleTrip {
  color: #044535;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(1.125rem, 2.5vw, 1.375rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.imgClientCard {
  height: 85%;
  width: 15%;
  margin: 1%;
  border-radius: 15px;
  flex-shrink: 0;
  object-fit: cover;
  font-size: clamp(1.5rem, 5vw, 50px) !important; 
}




.imgClientCard {
  width: 12.3125rem;
  /* height: 12.3125rem; */
  margin: 1%;
  border-radius: 0.9375rem;
  flex-shrink: 0;
  position: relative;

}

.imgClient{
  width: 10.3125rem;
  height: 10.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem; 
}

.imgClient img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.9375rem;

}


.imgClientCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.9375rem;

}

.imgClientCard .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: clamp(1rem, 2vw, 1.5rem);
  border-radius: 0.9375rem;
  cursor: pointer;
}


.imgClientCard .overlay {
  opacity: 1;
}
.mainInfoClient {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 70%;
  width: 25%;
  margin: 1%;
}

.infoClientName {
  color: #044535;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem);
}

.infoClientText {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 500;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem); 
  margin-top: 5%;
}

.infoEventText {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 500;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem); 
  margin-top: 0.625rem;
}

.infoClientTitleCard {
  color: rgba(15, 17, 17, 0.60);
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 1.5vw, 1rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  letter-spacing: 0.04rem;
}

.infoClientTitleCard2 {
  text-align: right;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1rem, 1.5vw, 1.375rem); 
  color: #044535 !important;
  margin-top: 10px;
}

.infoClientGreenCard {
  display: inline-flex;
  padding: 0.4375rem ;
  justify-content: center;
  align-items: center;
  gap: 0.625rem; 
  border-radius: 0.5rem; 
  background: #13BB56;
  height: 1.25rem; 
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
}

.infoClientGreenCardText {
  color: #FFF;
  font-family: 'Montserrat';
  font-size: clamp(0.5rem, 1.5vw, 0.625rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(0.35rem, 2vw, 0.65rem); 
}


.infoClientTopRight {
  position: absolute;
  right: 2%;
  margin-top: 0.3125rem; 
}

.infoClientTopRightSmall {
  position: absolute;
  right: 12%;
  margin-top: 0.3125rem; 
}

.infoClientBottomRight {
  position:absolute;
  right: 10px;
  bottom: 5px;
  height: min-content;
}



.infoIdText {
  color: #044535;
  text-align: right;
  font-family: 'Montserrat';
  font-size: clamp(0.875rem, 1.5vw, 1rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2vw, 1.375rem); 
}


.clientInfo::after {
  border-bottom: none !important;
  border-color: transparent !important;
}

.clientInfo::before {
  border-bottom: none !important;
  border-color: transparent !important;
}

.bottomRowInfoClient {
  margin-top: 1.875rem; 
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.bottomRowInfoClient2 {
  margin-top: 1.875rem; 
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.bottomRowEdit {
  margin-top: 1.875rem; 
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.bottomColumn {
  position: absolute;
  bottom: 0.625rem; 
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

@media (max-height:750px) {
  .whiteEditInfoCard{
    height:50%
  }
  .whiteEditInfoCardEvaluation{
    height:40%
  }
  .bottomRowInfoClient{
    margin-top: 1.25rem
  }
}


.clientInfoEditButton {
  width: 20.625rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1rem, 1.5vw, 1.125rem); 
  margin-inline: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .clientInfoEditButton {
    width: 15rem;  /* Ajuste o tamanho do botão para telas menores */
    height: 2.2rem;  /* Ajuste a altura para telas menores */
    font-size: clamp(0.75rem, 2vw, 0.875rem);  /* Ajuste o tamanho da fonte */
    line-height: clamp(0.9rem, 2vw, 1rem);  /* Ajuste o line-height */
    margin-inline: 8px;  /* Reduza a margem lateral */
  }
}

.clientInfoEditButton2 {
  width: 20.625rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1rem, 1.5vw, 1.125rem); 
  margin-inline: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .clientInfoEditButton2 {
    width: 7rem;  /* Ajuste o tamanho do botão para telas menores */
    height: 2.2rem;  /* Ajuste a altura para telas menores */
    font-size: clamp(0.75rem, 2vw, 0.875rem);  /* Ajuste o tamanho da fonte */
    line-height: clamp(0.9rem, 2vw, 1rem);  /* Ajuste o line-height */
    margin-inline: 8px;  /* Reduza a margem lateral */
  }
}

.clientInfoPreferencesButton {
  width: 80px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15);
  border: 0;
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 0.75rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.875rem, 1.5vw, 0.875rem); 
  margin-inline: 10px;
  border-color: #13BB56;
  cursor: pointer;
}

.clientInfoCancelButton {
  width: 20.625rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border-color: #13BB56;
  border: 0.0625rem solid #13BB56; 
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.875rem, 1.5vw, 1.125rem); 
  margin-inline: 0.625rem; 
  cursor: pointer;
}

.clientInfoCancelButton2 {
  width: 20.625rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border-color: #13BB56;
  border: 0.0625rem solid #13BB56; 
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.875rem, 1.5vw, 1.125rem); 
  margin-inline: 0.625rem; 
  cursor: pointer;
}

.personalDataPwd {
  width: 9.1875rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border-color: #13BB56;
  border: 0.0625rem solid #13BB56; 
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.625rem, 1vw, 0.75rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  cursor: pointer;
}


.personalDataPwd:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}

.clientInfoEditButton:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}

.clientInfoCancelButton:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}
.clientInfoCancelButton2:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}

.clientInfoEditButtonShort {
  width: 13.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  margin-inline: 0.625rem; 
  cursor: pointer;
}

.clientInfoCancelButtonShort {
  width: 13.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border-color: #13BB56;
  border: 0.0625rem solid #13BB56;
  color: #13BB56;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  margin-inline: 0.625rem; 
  cursor: pointer;
}


.clientInfoEditButtonShort:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}



.clientInfoCancelButtonShort:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}

.clientInfoEditButtonShort2 {
  width: 13.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  border: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  margin-inline: 0.625rem; 
  cursor: pointer;
}



.clientInfoEditButtonShort2:hover {
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 0%, rgba(44, 52, 44, 0.20) 100%), #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
}

body {
  overflow-y: hidden;
  overflow-x: hidden;
}

.select-register {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 0.0625rem solid #13BB56; 
  color: rgba(15, 17, 17, 0.40);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 0.875rem);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  letter-spacing: 0.04rem; 
  outline: none;
}

.select-register-gender {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 0.0625rem solid #13BB56 !important; 
  color: rgba(15, 17, 17, 0.40);
  font-family: 'Montserrat';
  font-size: clamp(0.75rem, 1vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1rem, 1.5vw, 1.5rem); 
  letter-spacing: 0.04rem; 
  outline: none;
}


.birthDateLabel {
  color: rgba(15, 17, 17, 0.40) !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.04rem; 
}

.birthDateLabel::placeholder {
  color: rgba(15, 17, 17, 0.90) !important;
  font-size: clamp(0.75rem, 1vw, 0.875rem) !important;
}


.centerContent {
  flex-direction: column;
  margin-left: 3.75rem;
  /* margin: 5%; */
  display: flex;
  /* width: 100%; */
  
}

.centerContent2 {
  flex-direction: column;
  margin-left: 3.75rem; 
  width: 70.625rem;
}

.dashboardClientTitle {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.2rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.525rem; 
  /* margin-bottom: 0.525rem; */
  width: 21.875rem; 
}


.horizontal-scroll-container {
  position: relative;
  overflow: hidden;
  width: 44rem;
  height: 13.125rem;
}

.horizontal-scroll-container-city {
  position: relative;
  overflow: hidden;
  width: 44rem;
  height: 7.2rem;
}

.cards {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  width: 43.75rem;
  height: 13.125rem;
}

.cards::-webkit-scrollbar {
  display: none; 
}

.cardscity {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  width: 43.75rem; 
  height: 7rem;
}

.cardscity::-webkit-scrollbar {
  display: none; 
}

.card {
  flex: 0 0 auto;
  margin-right: 0.625rem; 
  border-radius: 0.9375rem; 
  width: 9.375rem;
  height: 13.125rem;
  flex-shrink: 0;
  background-size: cover;
  background-position: bottom;
  cursor:pointer;
}

.event {
  flex: 0 0 auto;
  margin-right: 0.625rem; 
  border-radius: 0.9375rem; 
  width: 13.3125rem;
  height: 21rem; 
  flex-shrink: 0;
  background-size: cover;
  background-position: bottom;
  cursor: pointer;
}

.darkEndEvent {
  background: linear-gradient(180deg, rgba(33, 36, 33, 0) 53.65%, #2C342C 100%);
  width: 13.3125rem; 
  height: 21rem; 
  flex: 0 0 auto;
  border-radius: 0.9375rem;
  background-size: cover;
  background-position: bottom;
}

.city {
  flex: 0 0 auto;
  margin-right: 0.625rem; 
  border-radius: 0.9375rem; 
  width: 13.125rem; 
  height: 7rem;
  flex-shrink: 0;
  background-size: cover;
  background-position: bottom;
 
}

.darkEnd {
  background: linear-gradient(180deg, rgba(33, 36, 33, 0) 53.65%, #2C342C 100%);
width: 9.375rem;
  height: 13.125rem;
  flex: 0 0 auto;
  border-radius: 0.9375rem; 
  background-size: cover;
  background-position: bottom;
}

.darkEndCity {
  border-radius: 0.9375rem; 
  background: linear-gradient(180deg, rgba(44, 52, 44, 0.00) 53.65%, #2C342C 100%);
  flex: 0 0 auto;
  background-size: cover;
  background-position: bottom;
  width: 13.125rem; 
  height: 7rem;
  border-color: transparent !important;
}

.scroll-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: rgba(255, 250, 250, 0.8);
  color: #044535;
  border: none;
  border-radius: 50%;
  width: clamp(1.5rem, 2vw, 2rem); 
  height: clamp(1.5rem, 2vw, 2rem); 
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-weight: 700;
  font-family: "Montserrat";
  cursor: pointer;
}


.scroll-button.left {
  left: 2%;

}

.scroll-button.right {
  right: 2%;
}

.scroll-button-city {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(4, 69, 53, 0.6);
  color: rgba(255, 250, 250, 0.8);
  border: none;
  border-radius: 50%;
  width: 1.875rem; 
  height: 1.875rem; 
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-weight: 700;
  font-family: "Montserrat";
  cursor: pointer;
}


.scroll-button-city.left {
  left: 2%;

}

.scroll-button-city.right {
  right: 2%;
}

.event-info {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  padding-top: 10.125rem;
  padding-left: 0.625rem; 
}

.city-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 6rem;
  padding-left: 0.625rem; 
  z-index: 999;
  cursor: pointer;
}

.event-info-list {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  padding-top: 17rem; 
  padding-left: 0.625rem; 
}

.city-name {
  color: #FFF;
  font-family: "Montserrat";
  font-size: clamp(0.45rem, 1vw, 0.65rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1.1vw, 0.875rem); 
}

.event-day {
  color: #FFF;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1.5rem, 3vw, 1.625rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1rem, 2vw, 1rem); 
}

.event-month {
  color: #FFF;
  margin-top: 0.125rem; 
  font-family: "Montserrat";
  font-size: clamp(0.85rem, 1.5vw, 0.9rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1rem, 1.8vw, 1rem);
}

.event-name {
  display: flex;
  width: 5rem; 
  height: 1.725rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  color: #FFF;
  font-family: "Montserrat";
  font-size: clamp(0.65rem, 1vw, 0.75rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1vw, 0.875rem); 
  margin-left: 0.2rem; 
  margin-right: 0.2rem; 
  margin-top: 0.8rem;
}

.event-name-list {
  display: flex;
  width: 9.375rem; 
  height: 1.625rem; 
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  color: #FFF;
  font-family: "Montserrat";
  font-size: clamp(0.65rem, 1vw, 0.75rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 1vw, 0.875rem); 
  margin-left: 1.25rem; 
}


.mapStyleStats {
  width: 80%; 
  height:34.3125rem;
  border-radius: 0.9375rem; 
  display: flex;
  margin-left: 3.45rem; 
}
.mapStyleBig {
  width: 95%; 
  height:44.3125rem;
  border-radius: 0.9375rem; 
  display: flex;
  /* margin-left: 3.45rem;  */
}
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 3.0375rem; 
  margin-top: 0.95rem;
  width: 37.7rem;
}

.gallery-page {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.gallery-page:first-child {
  display: flex;
}

.navigation {
  display: flex;
  flex-direction: row;
}

.nav-ball {
  width: 0.3125rem; 
  height: 0.3125rem; 
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 0.3125rem; 
  cursor: pointer;
}

.nav-ball.active {
  background-color: #333;
}


.cardOffers {
  flex-direction: row;
  display: flex;
  width: 20.7125rem; 
  height: 5rem; 
  flex-shrink: 0;
  border-radius: 0.9375rem; 
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  margin-inline: 0.925rem; 
  margin-bottom: 0.2125rem; 
  position: relative
}


.newtrip {
  width: 19.5rem; 
  height: 3.125rem;
  flex-shrink: 0;
  border-radius: 0.5rem; 
  background: #13BB56;
  color: #FFF;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1.25rem, 2.5vw, 1.5rem);
  letter-spacing: 0.8px;
  border: none;
  cursor: pointer;
}


.bottomEnd {
  width: 100%; 
  align-items: center;
  justify-content: center;
  margin-right: 7.5rem; 
  display: flex;
  position: relative;
  margin-bottom: 0.55rem; 
  margin-top: 0.35rem;
}

.nearbyDiv {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}

.mapStyle {
  width: 21.3125rem; 
  height: 19.125rem;
  border-radius: 0.9375rem; 
  display: flex;
  margin-left: 1.2rem; 
}
@media (max-height: 860px) { 
  .nearbyDiv {
    margin-top: -5.5625rem; 
  }

  .mapStyle {
    width: 21.3125rem; 
    height: 13.125rem;
    border-radius: 0.9375rem; 
    display: flex;
    margin-left: 1.2rem; 
  }
}

@media (max-height: 750px) { 
  .nearbyDiv {
    margin-top: -5.1625rem; 
  }
}

@media (max-width: 1200px) { 
  .nearbyDiv {
    display: none; 
  }

  .mapStyle {
 display:none
  }
}
.horizontal-scroll-container {
  position: relative;
  overflow: hidden;
  width: 44rem;
  height: 13.125rem;
}

@media (max-width: 950px) {

  .horizontal-scroll-container {
    width: 30rem; 
    margin-inline:20px;
    height: 11.525rem;
  }

  .cards {
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    scroll-behavior: smooth; 
  }

  .card, .darkEnd {
    width: 7.125rem; 
    height: 11.125rem;
    flex-shrink: 0; 
  }

  .event-info {
    padding-top: 8.125rem;
    padding-left: 0.5rem; 
  }
}

@media (max-width: 768px) {
  .centerContent {
    flex-direction: column;
    margin: 1%;
    display: flex;
    align-items: center;
    width: 100%;
    
  }
  .horizontal-scroll-container {
    width: 30rem; 
    margin-inline: 20px; 
    height:9.425rem;
  }

  .cards {
    display: flex;
    overflow-x: auto;
    gap: 0.3rem;
    scroll-behavior: smooth; 
  }

  .card, .darkEnd {
    width: 5.5rem;
    height: 9.5rem;
    flex-shrink: 0; 
  }

  .event-info {
    padding-top: 6.5rem; 
    padding-left: 0.2rem;
  }

  .event-day {
    font-size: 1rem; 
  }

  .event-month {
    font-size: 0.6rem;
  }

  .event-name {
    margin-left: 0; 
    font-size: 0.5rem; 
    width: 3.5rem; 
    text-align: center; 
  }
}

@media (max-width: 500px) {
  .centerContent {
    
    margin-top: 40px !important;
  }
}
@media (max-height: 700px) {
  .centerContent {
    overflow-y: auto !important;
    max-height: 80vh;
    min-height: 80vh;
    margin-top: 0px !important;
  }
}

@media (max-width: 500px) {
  .centerContent {
    flex-direction: column;
    margin: 0%;
    display: flex;
    width: 100%;
    
  }
  .horizontal-scroll-container {
    width: 100%; 
    margin-inline: 20px;
  }

  .cards {
    gap: 0.2rem; 
  }

  .card, .darkEnd {
    width: 4.5rem; 
    height: 8rem;
  }

  .event-info {
    padding-top: 5rem; 
    padding-left: 0.1rem;
  }

  .event-day {
    font-size: 0.8rem; 
  }

  .event-month {
    font-size: 0.5rem;
  }

  .event-name {
    font-size: 0.4rem;
    width: 3rem;
  }
  .bottomEnd {
   
    margin-right: 0; 
  
  }
  
}




.nearbyCard {
  border-radius: 0.9375rem; 
  background: rgba(17, 96, 48, 0.20);
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 21.3125rem;
  height: 4.8125rem; 
  flex-shrink: 0;
  margin: 0.3125rem; 
  flex-direction: row;
  display: flex;
  position: relative;
}

.nearbyText {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2.5vw, 1.5rem); 
}

.nearbyImage {
  width: 3.5625rem; 
  height: 3.5625rem; 
  margin: 0.625rem; 
  border-radius: 0.5rem; 
  flex-shrink: 0;
  background-size: cover;
  background-position: bottom;
}

.rightEnd {
  position: absolute;
  bottom: 0;
  right: 4rem;
}

.buttonGreen {
  border-radius: 0.5rem; 
  background: #13BB56;
  width: 3.125rem; 
  height: 1.5625rem; 
  flex-shrink: 0;
  color: #FFFF;
  font-size: clamp(1rem, 1.5vw, 1.125rem);
  font-weight: 700;
  font-family: "Montserrat";
  cursor: pointer;
  border: none;
  position: absolute;
  bottom: 0.5rem; 
}

.buttonDarkGreen {
  border-radius: 0.5rem; 
  background: #044535;
  width: 3.125rem; 
  height: 1.5625rem; 
  flex-shrink: 0;
  color: #FFFF;
  font-size: clamp(1rem, 1.5vw, 1.125rem); 
  font-weight: 700;
  font-family: "Montserrat";
  cursor: pointer;
  border: none;
  position: absolute;
  bottom: 0.5rem; 
}

.offersText {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 10.625rem; 
}

.offersText2 {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1.5vw, 1rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.3125rem; 
  max-width: 10.625rem; 
}

.nearbyName {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1.5vw, 1rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 11.25rem;
  margin-top: 0.3125rem; 
}

.nearbyType {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.75rem, 1vw, 0.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.625rem; 
}

.arrowDown {
  color: rgba(15, 17, 17, 0.60);
  font-size: clamp(0.65rem, 1vw, 0.75rem); 
  margin-left: 0.625rem; 
  font-family: "Montserrat";
}

.activeText {
  color: #044535;
  font-family: "Montserrat";
  font-size: clamp(1rem, 1.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; 
}

.activeTextEdit {
  color: #044535;
  font-family: "Montserrat";
  font-size: clamp(1rem, 1.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; 
  border-radius: 0.5rem; 
  background: rgba(15, 17, 17, 0.05);
  width: 7.0625rem; 
  height: 1.6875rem;
  flex-shrink: 0;
  padding-inline: 0.5rem; 
  padding-top: 0.25rem; 
  padding-bottom: 0.25rem; 
}

.deleteBtn {
  border-radius: 0.5rem !important;
  background: #ED4F2C !important;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  width: clamp(8rem, 40%, 13.8125rem) !important; /* Largura responsiva */
  height: 2.5rem; 
  flex-shrink: 0;
  color: #FFF !important;
  text-align: center;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  font-size: clamp(0.75rem, 1.5vw, 1rem) !important; /* Tamanho de fonte responsivo */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0.875rem !important; 
  margin-inline: 1.25rem !important; 
}

.suspendBtn {
  border-radius: 0.5rem !important;
  background: #FFCD56 !important;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  width: clamp(8rem, 40%, 13.8125rem) !important; /* Largura responsiva */
  height: 2.5rem; 
  flex-shrink: 0;
  color: #FFF !important;
  text-align: center;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  font-size: clamp(0.75rem, 1.5vw, 1rem) !important; /* Tamanho de fonte responsivo */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0.875rem !important; 
  margin-inline: 1.25rem !important; 
}

.activateBtn {
  border-radius: 0.5rem !important;
  background: #13BB56 !important;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  width: clamp(8rem, 40%, 13.8125rem) !important; /* Largura responsiva */
  height: 2.5rem;
  flex-shrink: 0;
  color: #FFF !important;
  text-align: center;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  font-size: clamp(0.75rem, 1.5vw, 1rem) !important; /* Tamanho de fonte responsivo */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.25rem !important; /* Linha ajustada para responsividade */
  margin-inline: 1.25rem !important;
}

.cancelBtn {
  color: #13BB56 !important;
  text-align: center !important;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  font-size: clamp(0.75rem, 1.5vw, 1rem) !important; /* Tamanho de fonte responsivo */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.25rem !important; /* Linha ajustada para responsividade */
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #13BB56 !important;
  background: #FFF !important;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important;
  width: clamp(8rem, 40%, 13.8125rem) !important; /* Largura responsiva */
  height: 2.5rem;
  margin-inline: 1.25rem !important;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .activateBtn,
  .cancelBtn, .suspendBtn {
  
    margin-inline: auto; /* Centraliza os botões horizontalmente */
    height: 2.25rem; /* Altura menor para mobile */
  }

  .activateBtn, .suspendBtn {
    font-size: 0.875rem; /* Ajuste para texto legível no mobile */
  }

  .cancelBtn {
    font-size: 0.875rem; /* Ajuste para texto legível no mobile */
  }
}


.textCancel {
  color: #044535;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1rem, 1.5vw, 1.25rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.imageGrid {
  border-radius: 15px;
  width: 4.8125rem; 
  height: 5rem;
  flex-shrink: 0;
  object-fit: cover;
  
}

.eventGridText {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.6rem, 1.5vw, 1.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.custom-row {
  background: rgba(17, 96, 48, 0.20) !important;
  border-radius: 15px; 
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15);
  height: 5rem; 
  margin-top: 0.3125rem; 
  margin-bottom: 0.3125rem; 
  width: 100%;
}

.dateEventText {
  color: rgba(15, 17, 17, 0.60) !important;
  font-family: "Montserrat" !important;
  font-size: clamp(0.2rem, 1vw, 0.325rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0.625rem;
}


.addPosterBig {
  width: "100%"; 
  height: 8.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
}


.addPosterBig img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
}

.addPoster {
  width: 4.0625rem; 
  height: 4.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
}


.addPoster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
}

.addProfile {
  width: 12.3125rem;
  height: 10.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem; 
}


.addProfile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
}

.addProfilePhoto {
  width: 12.3125rem;
  height: 12.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem; 
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}


.addPhoto {
  width: 4.0625rem; 
  height: 4.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
  background: rgba(15, 17, 17, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}

.addPhotoBig {
  width: "100%"; 
  height: 8.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
  background: rgba(15, 17, 17, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}

.photosEvent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.photoColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profileColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin:1rem
}


.photoLabel {
  color: #044535;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; 
  margin-bottom: 0.625rem; 
}


.addPosterEdit {
  position: relative;
  width: 4.0625rem; 
  height: 4.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
  overflow: hidden;
  cursor: pointer;
}

.addPosterEdit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
  transition: opacity 0.3s ease;
}

.addPosterEdit .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addPosterEdit:hover .overlay {
  opacity: 1;
}

.addPosterEdit:hover .hoverIcon {
  opacity: 1;
}


.addPosterEditBig {
  position: relative;
  width: "100%"; 
  height: 8.0625rem; 
  flex-shrink: 0;
  border-radius: 0.625rem; 
  overflow: hidden;
  cursor: pointer;
}

.addPosterEditBig img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
  transition: opacity 0.3s ease;
}

.addPosterEditBig .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addPosterEditBig:hover .overlay {
  opacity: 1;
}

.addPosterEditBig:hover .hoverIcon {
  opacity: 1;
}



.addProfileEdit {
  position: relative;
  width: 12.3125rem;
  height: 12.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem; 
  overflow: hidden;
  cursor: pointer;
}

.addProfileEdit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.625rem; 
  transition: opacity 0.3s ease;
}

.addProfileEdit .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addProfileEdit:hover .overlay {
  opacity: 1;
}

.addProfileEdit:hover .hoverIcon {
  opacity: 1;
}
/* Estilos responsivos para telas menores (mobile) */
@media (max-width: 768px) {
  .addProfileEdit {
    width: 8rem;  /* Tamanho reduzido para mobile */
    height: 8rem; /* Tamanho reduzido para mobile */
  }

  .addProfileEdit img {
    object-fit: cover;
  }

  .addProfileEdit .overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%); /* Menos opacidade para mobile */
  }
}

/* Ajuste para telas menores que 480px */
@media (max-width: 480px) {
  .addProfileEdit {
    width: 6rem; /* Mais reduzido */
    height: 6rem; /* Mais reduzido */
  }

  .addProfileEdit .overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%); /* Mais leve no mobile */
  }
}
.hoverIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(0.75rem, 1.5vw, 1rem); 
  color: #FFF;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.morePhotos {
  margin-left: 1.25rem;
}
.morePhotos2 {
  margin-left: 0rem;
  margin-bottom: 2rem;
  width: 100%;
}

.photoRow {
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem; 
align-items: left;

}
.eventElement {
  margin-top: 2.5rem; 
  display: flex;
  flex-direction: column;
}

.orderTitle {
  color: #044535;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem); 
}

.orderName {
  color: #044535;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.625rem;
}

.orderText {
  color: #044535;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.625rem;
}

.aproveBtn {
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 13.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 0.875rem); 
  margin-inline: 1.25rem; 
  border:none;
  cursor: pointer;
}

.aprovedBtn {
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 15.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 0.875rem);
  margin-inline: 1.25rem; 
  margin-top: 1.25rem;
  border:none;
}

.canceledRedBtn {
  color: #ED4F2C;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 0.875rem); 
  border-radius: 0.5rem; 
  border: 0.0625rem solid #ED4F2C; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 15.8125rem; 
  height: 2.5rem; 
  margin-inline: 1.25rem;
  margin-top: 1.25rem;
}

.cancelRedBtn {
  color: #ED4F2C;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 0.875rem); 
  border-radius: 0.5rem; 
  border: 0.0625rem solid #ED4F2C; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 13.8125rem; 
  height: 2.5rem; 
  margin-inline: 1.25rem; 
  cursor: pointer;
}

.EventsTitle {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem); 
  position: absolute;
  margin-top: 0px;
  left: 44.8%;
}


.textlinkcode {
  color: #0F1111;
  text-align: center;
  font-family: 'Montserrat';
}

.scrollableTransparentDiv {
  position: relative;
  background-color: white;
  height: 72%; 
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 60%; 
  margin: 0 auto;
  border-radius: 1.0625rem 1.0625rem 0.5rem 0.5rem;
  margin-top: -5rem !important; 
}

.skeletonDiv {
  position: relative;
  background-color: transparent;
  height: 92%; 
  width: 60%; 
  margin: 0 auto;
  border-radius: 1.0625rem 1.0625rem 0.5rem 0.5rem;
  margin-top: -12rem !important; 
  display: flex;

  
}


.eventbigcard {
  background-color: transparent;
  border-radius: 0.9375rem; 
  width: 100%;
  flex-shrink: 0;
  margin: 0 auto;
  position: relative;
}

.eventbigcardloading {
  background-color: transparent;
  border-radius: 0.9375rem; 
  width: 100%;
  flex-shrink: 0;
  height: 31.25rem; 
  margin: 0;
  position: relative;
  top:0
}

.eventbigcardImage {
  width: 100%;
  height: 31.25rem; 
  object-fit: cover;
  border-radius: 0.9375rem; 
  flex-shrink: 0;
}
.fullWidthCard {
  position: absolute;
  bottom: 1.5625rem; 
  left: 2%;
  margin-right: 1.25rem; 
  width: 96%;
  height: 9.375rem; 
  border-radius: 0.5rem; 
  background: #FFF;
}

.cardContent {
  text-align: left;
  display: flex;
  flex-direction: column;
  height:"300px";
}

.cardContentRight {
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0.9375rem; 
  right: 0;
}

.eventTitle {
  margin-left: 1.25rem;
  margin-top: 1.25rem; 
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1.5rem, 2.5vw, 1.725rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 100%; 
}

.eventText {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.125rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 1.25rem; 
}


.infoRow {
  margin-top: 2.25rem; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.25rem; 
}

.priceCard {
  display: flex;
  width: 4.9375rem; 
  height: 3.5rem; 
  flex-direction: column;
  justify-content: center;
  margin-left: 2.1875rem; 
  flex-shrink: 0;
  border-radius: 0.375rem; 
  background: rgba(19, 187, 86, 0.50);
}

.sinceText {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1vw, 0.75rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.valueText {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 700 !important;
  line-height: 1.375rem; 
  letter-spacing: 0.8px;
}

.freevalueText {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700 !important;
  line-height: 1.375rem; 
  letter-spacing: 0.8px;
}


.infoEventCard {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 1.875rem; 
  margin-top: 1.25rem;
}

.eventcardSmall {
  width: 10.6875rem; 
  height: 15.5rem; 
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 0.5rem; 
  margin-bottom: 0.625rem; 
  cursor: pointer;
}

.cardContent {
  text-align: left;
  display: flex;
  flex-direction: column;

}

.cardContentInfo {
  text-align: left;
  display: flex;
  flex-direction: column;
  width:100%

}


.description {
  height: 100px; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}


.eventInfoTitle {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.75rem, 1vw, 0.875rem); 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eventInfoText {
  margin-top: 0.625rem; 
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1vw, 0.75rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 90%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}


.textRow {
  margin-top: 1.25rem; 
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: flex-start;

}


.partnershipText {
  margin-top: 0.3125rem; 
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(0.5rem, 1vw, 0.625rem); 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.TitleTicket {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1.25rem, 2vw, 1.5rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tripSelectorGreen {
  height: 1.875rem; 
  flex-shrink: 0;
  border-radius: 0.9375rem; 
  background: #13BB56;
  flex-direction: row;
  border: none;
  margin-inline: 0.3125rem; 
  align-items: center;
  color: #FFF;
  font-family: "Montserrat";
  font-size: clamp(0.75rem, 1vw, 0.875rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.tripSelectorGrey {
  height: 1.875rem; 
  flex-shrink: 0;
  border-radius: 0.9375rem; 
  background: rgba(15, 17, 17, 0.10);
  flex-direction: row;
  padding: 0.3125rem;
  border: none;
  align-items: center;
  margin-inline: 0.3125rem; 
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.75rem, 1vw, 0.875rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}


.stationPicker {
  position: relative;
  border-radius: 0.5rem; 
  background: #f0f0f0;
  margin-inline: 1.0625rem; 
  padding: 0.5rem 0 0.5rem 0; 
}

.stationPickerOpen {
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0; 
  background: #F3F3F3;
  margin-inline: 1.0625rem; 
  padding: 0.5rem 0 0.5rem 0; 
}

.stationPickerLabel {
  color: rgba(15, 17, 17, 0.60);
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1vw, 0.75rem);
  font-weight: 500;
  margin-left: 0.625rem; 
}

.stationSearch {
  background-color: transparent;
  border: none;
  margin-left: 0.625rem;
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1vw, 1rem); 
  font-weight: 600;
  width: 98%;
  outline: none;
}


.infoDropdown {
  position: relative;
  width: 100%;
  border-radius: 0.5rem; 
  cursor: pointer;
  background: #F3F3F3;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 18.75rem;
  overflow-y: auto;
  background: #F3F3F3;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem; 
  width: 100%;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownItem {
  padding: 0.625rem; 
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 1vw, 1rem); 
  font-weight: 600;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.lightbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}

.lightbox-content {
  position: relative;

}

.close-button {
  position: absolute !important;
  top: -0.625rem; 
  right: 0.625rem; 
}

.order-close-button {
  position: absolute !important;
 
  right: 0.625rem; 
}

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
  
}

.passangerLabel {
  color: rgba(15, 17, 17, 0.60);
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1vw, 0.75rem);
  font-weight: 500;
  line-height: normal;
  padding-left: 0.625rem; 
  height: 0.625rem; 
}


.event-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 0.9375rem; 
  overflow: hidden;
  background-color: #FFF;
  width: 22.5625rem; 
  height: 10.625rem; 
  flex-shrink: 0;
  transition: box-shadow 0.3s ease;
}

.event-container:hover {
  box-shadow: 0px 0.5rem 0.75rem rgba(0, 0, 0, 0.2);
}

.event-image2 {
  min-width: 9.375rem !important; 
  background-size: cover;
  background-position: center;
  border-radius: 0.9375rem; 
}
.trainstationmap {
  width: 7.375rem !important; 
  height: 100%;
  border-radius: 0.9375rem !important; 
  overflow: hidden;
}

.partnermap {
  margin-top: 1rem;
  width: 20.375rem !important; 
  height: 8.375rem !important;
  border-radius: 0.9375rem !important; 
  overflow: hidden;
}
.eventmap {
  margin-top: 1rem !important;
  margin-inline: 4% ;
  height: 12.375rem !important;
  border-radius: 0.9375rem !important; 
  overflow: hidden;
}
.event-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}

.event-title-name {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1.125rem, 2.5vw, 1.25rem); 
  height: 2.5rem; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-title-name2 {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1.125rem, 2.5vw, 1.25rem); 
  height: 2.5rem; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-day-dark {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1.5rem, 4vw, 1.625rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1rem, 4vw, 1rem); 
  margin-bottom: 0.3125rem; 
}

.event-month-dark {
  color: #0F1111;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(0.9rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1rem, 2vw, 1rem);
  margin-top: 0.3125rem; 
}

.event-location {
  margin-top: 0.3125rem; 
  font-size: clamp(0.75rem, 1.5vw, 0.875rem); 
  color: #0F1111;
}

.event-extra-date {
  margin-top: 0.3125rem; 
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  color: #666;
}

.eventTextSmall {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.75rem, 1vw, 0.75rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: clamp(-0.36px, -0.5vw, -0.36px); 
}


.scroll-container {
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-y: hidden;

}

.card-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  height: 13.125rem;
}

.event-card {
  flex: 0 0 auto;
  width: 9.5625rem; 
  margin-inline: 0.325rem; 
  border: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  height: 13.125rem;
}

.event-image {

min-height: 5.625rem !important;
  width: 9.5625rem;
  border-radius: 0.9375rem; 
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.date-label {
  background:transparent;
  color: white;
  padding: 0.3125rem 0.625rem; 
  border-radius: 0.25rem; 
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.date-day {
  color: #FFF;
  text-align: center;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 1rem); 
}

.date-month {
  color: #FFF;
  margin-top: 0.125rem; 
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1.5vw, 0.75rem); 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(0.75rem, 1.5vw, 1rem); 
}


.event-details {
  padding: 0.625rem; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.trainstation-details {
  padding: 0.625rem; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}


.event-title {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(1rem, 2.5vw, 1.125rem); 
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  width: 8.75rem;
  height: 3.8rem;  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
  position: relative;
}

.event-text {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: clamp(0.625rem, 1.5vw, 0.75rem); 
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
}


.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.625rem; 
  cursor: pointer;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.darkEndSmall {
  background: linear-gradient(180deg, rgba(33, 36, 33, 0) 53.65%, #2C342C 100%);
  width: 9.5625rem;
  height: 100%; 
  border-radius: 0.9375rem; 
}

.titleScrolls{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 74%;
  margin-top: 1.25rem;

}

.scrollContainerCustom {

  max-height: 500px; 
  overflow-y:visible;
  overflow-x: hidden; 
  display: flex;
  flex-direction: column; 
  scrollbar-width: thin;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
}

.rowCustom {
  display: flex;
  flex-wrap: wrap; 
  gap: 10px; 
  margin-top: 20px;
  height:70%
}

.cardCustom {
  background-color: #f0f0f0 !important;
  border-radius: 8px !important;
  cursor: pointer;
  width: clamp(100px, 20%, 200px); /* Largura responsiva */
  height: clamp(50px, 10vw, 100px); /* Altura responsiva */
  display: flex;
  align-items: center; /* Centraliza o texto verticalmente */
  justify-content: center; /* Centraliza o texto horizontalmente */
  margin: 0.5rem; /* Espaçamento entre os botões */
  text-align: center;
}

.selectedCardCustom {
  background-color: #13bb56 !important;
  color: white;
  border-radius: 8px !important;
  cursor: pointer;
  width: clamp(100px, 20%, 200px); /* Largura responsiva */
  height: clamp(50px, 10vw, 100px); /* Altura responsiva */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  text-align: center;
}

.textCustom {
  font-size: clamp(12px, 1.5vw, 16px); /* Tamanho de texto responsivo */
}

.selectedTextCustom {
  font-size: clamp(12px, 1.5vw, 16px);
  font-weight: bold;
  color: white;
}

/* Ajustes adicionais para dispositivos móveis */
@media (max-width: 768px) {
  .cardCustom,
  .selectedCardCustom {
    width: 90%; /* Ocupa quase toda a largura da tela */
    height: 70px; /* Altura fixa para mobile */
    margin: 0.5rem auto; /* Centraliza no contêiner */
  }

  .textCustom,
  .selectedTextCustom {
    font-size: 14px; /* Texto menor em telas pequenas */
  }
}


.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.imgClientCard {
  height: 100%;
  width: 100%;
  border-radius: 0.625rem;
  flex-shrink: 0;
  object-fit: cover;
  font-size: clamp(30px, 5vw, 50px) !important; 
}


.list-trips {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 0 10px;
}

.trip {
  background-color: #fff;
  font-family: "Montserrat";
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  width: 100%;
  max-width: 16rem;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.trip:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.trip h3 {
  font-family: "Montserrat";
  margin-top: 0;
  font-size: 1rem;
}

.trip p {
  font-family: "Montserrat";
  color: #444;
  font-size: 0.8rem;
}



.scroll-trips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 3rem;
  height: 44vh; 
  overflow-y: auto; 
}

.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em; 
  line-height: 1.5em; 
}

@media (max-width: 768px) {
  .trip {
    max-width: 100%;

    padding: 0px;
  }

  .trip h3 {
    font-size: 1rem;
    padding: 10px;
  }

  .trip p {
    font-size: 0.8rem;
    
  }

  .details-button {
    font-size: 0.75rem;
    padding: 6px 12px;
    margin-inline: 20px;
    margin-bottom: 10px;
  }
}


.details-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color:#11bb55 ;
  font-family: "Montserrat";
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color:rgba(19, 187, 86, 0.50);
}

.titleTrips {
  font-size: clamp(16px, 4vw, 20px);
  font-weight: 600;
  text-align: center;
  font-family: "Montserrat";
}


.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}

.modal-content {
  position: relative;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw; 
  height: 70vh;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}

.modal-close-button {
  position:absolute;
  top: 10px;
  right: 10px;
  display: flex;
}
.centerGraph{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .centerGraph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: "center";
    width: 100%;
    height:80vh;
    margin-top:4vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 768px) {
  .gallery-container{
    width: 100%;
    align-items: center;
    display:flex;
    justify-content: center;
    margin-left: 0;
  }
 
 
  .gallery-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
  }

  

 

  .rightEnd {
    display: flex;
    justify-content: flex-end;
    right: 0.5rem;
    bottom: 0rem;
  
  }

  .offersText, .offersText2 {
    font-size: 0.5rem !important; 
    
  }
  

  .navigation {
    display: flex;
    justify-content: center;
    margin-top: 0rem;
    margin-right: 0rem;
 
  }

  .nav-ball {
    width: 0.25rem;
    height: 0.25rem;
    margin: 0 0.25rem;
  }

  
}

@media (max-width: 389px) {
.cardOffers {
   
  margin-bottom: 0.5rem; 
  width:16.5rem;
  height: 3rem;
  display: flex;
 justify-content: flex-start;

}
}
.trip-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 20px;
}

.trip-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  font-family: "Montserrat";
  overflow: auto;
}

.trip-header {

  margin-bottom: 20px;
}

.trip-title {

  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.trip-dates {
  font-size: 1rem;
  color: #666;
}

.trip-stations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.trip-station {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.trip-station p {
  font-size: 1rem;
  font-weight: 500;
  color: #444;
}

.trip-co2 {
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #666;
}

.trip-map {
  height: 200px;
  margin-bottom: 20px;
border-radius: 0.935rem;
overflow: hidden;

}



@media (max-width: 768px) {
  .trip-container {
    padding: 15px;
    height:70% !important;
  }
  
  .trip-title {
    font-size: 1.4rem;
  }

  .trip-dates {
    font-size: 0.9rem;
  }

  .trip-stations {
    flex-direction: column;
    text-align: center;
  }

  .trip-station {
    padding: 8px 0;
  }

  .trip-map {
    height: 150px;
    border-radius: 0.9375rem; 
  }

  
}


.directions{
  flex-direction: row;
  display:flex;
  align-items: center;
  justify-content: center;

}

.directions a{

  font-family: 'Montserrat' !important;
  font-size: clamp(0.625rem, 1.5vw, 0.75rem) !important; 
  font-style: normal !important;
  font-weight: 600 !important;
  margin-inline: 10%;

}

#report-issue-container {
  position: fixed; 
  bottom: 5%; 
  right: 2.7%; 
  z-index: 1000;
}

#report-issue-button {
  background-color: white; 
  color: #28a745; 
  border: 1px solid #28a745;
  font-family: 'Montserrat' !important;
  padding: 10px 15px; 
  cursor: pointer; 
  font-size: 14px; 
  border-radius: 5px; 
  transition: background-color 0.3s, color 0.3s;
}


#report-issue-button:hover {
  background-color: #28a745;
  color: white;
}

.scrollContainerActivities2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.rowActivities {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; 
  width: 100%;
}

.activityCard,
.selectedActivityCard {
  width: 100%; 
  max-width: 300px; 
  padding: 5px;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.15) !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:  #13BB56 !important;
}

.selectedActivityCard {
  background-color: #13BB56 !important;
  color: #FFF !important;
}

.zoneGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  padding: 10px;
}


.zoneButton {
  background-color: transparent; 
  color: #fff; 
  border: 1px solid #fff; 
  padding: 10px;
  text-align: center;
  cursor: pointer;
  flex: 1 1 auto;
  min-width: 100px;
  border-radius: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
}


.zoneButton.selected {
  background-color: #fff; 
  color: #13BB56; 
  border-color: #13BB56; 
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.plan-selection {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem;
}

.plan-card {
  background-color: #ffffff; 
  border: 1px solid #dcdcdc; 
  border-radius: 8px;
  width: 180px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  font-family: 'Montserrat', sans-serif; 
}

.plan-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
  transform: translateY(-2px); 
}

.plan-card.selected {
  border-color: #2c6a6a; 
  background-color: #e6f7f7; 
  color: #2c6a6a; 
}

.plan-card:not(.selected) {
  color: #555; 
}

.plan-title {
  color: #333;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.plan-duration,
.plan-price {
  color: #666; 
  font-size: 0.95rem;
  margin: 0.3rem 0;
}

.no-plans {
  color: #888;
  font-size: 1rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif; 
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.buttonStep {
  background-color: #4caf50; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-inline: 10px;
  font-family: 'Montserrat', sans-serif; 
  width: 8rem;
}

.buttonStep:disabled {
  background-color: #d3d3d3;
  color: #888888;
  cursor: not-allowed;
}

.buttonStep:hover:not(:disabled) {
  background-color: #45a049; 
}

.buttonStep:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}
.simple-alert {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  padding: 15px;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.simple-alert h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.simple-alert p {
  margin: 0;
  font-size: 1rem;
}

.invertible-button {
  margin-left: 0.625rem;
  height: 1.813rem;
  width:7rem;
  border: 1px solid #044535;
  background-color: transparent;
  color: #044535;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat';
}

.invertible-button:hover {
  background-color: #044535;
  color: white;
}


.navclienthorizontal {
  display: flex;
  position: fixed;
  flex-direction: row;
  bottom: 0; 
  left: 0;
  right: 0;
  height: 4rem; 
  background-color: #13BB56; 
  align-items: center;
  justify-content: space-evenly; 
  border-radius: 15px 15px 0 0; 
  z-index: 106; 
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15); 
}

.navmanagerhorizontal {
  display: flex;
  position: fixed;
  flex-direction: row;
  bottom: -1px; 
  left: 0;
  right: 0;
  height: 8rem; 
  background-color: #13BB56; 
  align-items: center;
  justify-content: space-evenly; 
  border-radius: 15px 15px 0 0; 
  z-index: 106; 
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15); 
}

.navIcon {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-repeat: no-repeat;
}


.navIcon:active {
  transform: scale(0.95);
  opacity: 0.8;
}


@media (max-width: 768px) {
  .horizontal-scroll-container-city {
    width:  91%; 
    height: auto; 
    margin-inline:20px;

  }
  .city-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 4rem;
    padding-left: 0.625rem; 
    z-index: 999;
    cursor: pointer;
  }

  .cardscity {
    width: 100%; 
    height: auto;
  }

  .city {
    width: 9rem; 
    margin-right: 0.625rem; 
    height: auto; 
    aspect-ratio: 16 / 9; 
  }

  .darkEndCity {
    width: 9rem;  
    height: auto; 
    aspect-ratio: 16 / 9; 
  }

  .scroll-button-city {
    width: 1.5rem; 
    height: 1.5rem;
    font-size: clamp(0.75rem, 2vw, 1rem); 
  }

  .city-name {
    font-size: clamp(0.4rem, 2vw, 0.55rem); 
    line-height: clamp(0.65rem, 1vw, 0.8rem); 
  }
  .dashboardClientTitle{
    margin-left: 3%;
  }

}

@media (max-width: 768px) {
 
  .greenTitle {
    font-size: 1.2rem; 
    text-align: center; 
    margin-left: 0rem;
    padding-top: 0rem;
  }
  .whiteBoard {
    width: 80%; 
    height: 60%;
    padding: 0px; 
margin-left: 0rem;
margin-bottom:2.5rem;
margin-top: 1rem;

  }
  .scrollableContent {
    overflow-x: hidden;
    overflow-y: auto; 
  }
  #report-issue-container {
    text-align: center; 
    bottom: 9%;
  }
  #report-issue-button {
    width: 100%;
  }

  .scroll-trips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
   
    margin-left: 0rem;
    height: 42vh; 
    overflow-y: auto; 
  }

  .topLeftBackTrip {
    position: absolute;
    left: 0.25rem; 
    top: 11.25rem; 
    display: flex;
  }

  .whiteInfoCard{
    margin-left:0rem;
    height: 20%;

  }

  .whiteInfoCard3{
    margin-left:0rem;
    height: 40%;

  }
  .whiteInfoCardClient{
    margin-left:0rem;
    height: 40%;

  }
  .whiteEditInfoCard{
    margin-left: 0rem;
    height:35%;
    margin-bottom:3vh
  }
  .greenTitleClientData{
    padding-top: 1rem;
  }
  .mainInfoClient{
    height: 40%;
  }
  .addProfile {
    width: 6.3125rem;
    height: 5.3125rem;
    flex-shrink: 0;
    border-radius: 0.625rem; 
  }
  .centerContent2 {
    flex-direction: column;
    margin-left: 0rem; 
    width: 100%;
  
    display: flex;
  }
  .event-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 0.9375rem; 
    overflow: hidden;
    background-color: #FFF;
    width: 21rem; 
    height: 10.625rem; 
    flex-shrink: 0;
    transition: box-shadow 0.3s ease;
  }

  .EventsTitle{
    margin-top: -50px;
    left: 29%;
    align-self:center;
   
  }

  .event-title-name{
    overflow:hidden;
    text-overflow:initial;
    display: flex;
    height: 4.5rem;

  }
  .event-title-name2{
    overflow:hidden;
    text-overflow:initial;
    display: flex;
    height: unset;

  }
  .scrollableTransparentDiv{
    width:80%;
  }
  .infoRow{
    margin-top: 2rem;
  }
  .eventTitle{
    font-size: 0.8rem;
    max-width: 60%;
    white-space: inherit;
    text-overflow: initial;
    overflow: hidden;

  }
  .eventText{
    margin-left: 0.25rem;
    font-size:0.5rem;
   
  }
  .infoEventCard{
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-left: 0rem;
  }
  .textRow{
    margin-top: 0rem;
    width: 90%;
    margin-bottom: 20px;
  }

  .eventInfoText{
    margin-top: 0rem;
  }

  .clientInfoEditButtonShort{
    width: 25%;
  }
  .clientInfoCancelButtonShort{
    width: 25%;
  }
  /* .cardContentRight{
    top:auto;
  } */
  .partnermap {
    margin-top: 1rem;
    width: 88%!important; 
    height: 8.375rem !important;
    border-radius: 0.9375rem !important; 
    overflow: hidden;
    margin-bottom: 1rem;
  }
  .eventmap {
    margin-top: 1rem;
    width: 88%!important; 
    height: 8.375rem !important;
    border-radius: 0.9375rem !important; 
    overflow: hidden;
    margin-bottom: 1rem;
  }
  .event-date{
    padding:0.1rem;
  }
  
.MuiDataGrid-main {
  margin-inline: 0rem; 
}

.eventGridText {
  color: #0F1111;
  font-family: "Montserrat";
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
}


@media (max-width: 380px) {
  .whiteInfoCard3{
   margin-top: 0.55rem;

  }
  .greenTitleClientData{
    padding-top: 1.7rem;
  }
  .mainInfoClient{
    margin:0%;
  }
}

/* Container base */
.state-cell-container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}

/* Base para o botão */
.state-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  width: 120px;
  height: 40px;
  color: #fff;
}

/* Cores dinâmicas */
.state-cell.paid {
  background-color: #13bb56;
}

.state-cell.unpaid {
  background-color: #ed4f2c;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .state-cell {
    font-size: 14px; /* Menor tamanho de fonte */
    width: 80px; /* Botão mais compacto */
    height: 30px; /* Altura reduzida */
  }
}
/* Container principal */
.custom-state-cell-container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}

/* Wrapper principal */
.custom-state-cell-container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}

/* Estilo base para o link */
.custom-state-cell {
  border-radius: 5px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px; /* Fonte original */
  font-weight: 500;
  width: 120px; /* Largura original */
  height: 40px; /* Altura original */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease; /* Animação ao mudar estado */
}

/* Cores para diferentes estados */
.custom-state-cell.custom-approved {
  background-color: #13bb56; /* Verde para aprovado */
}

.custom-state-cell.custom-pending {
  background-color: #0f111199; /* Cinza para pendente */
}

.custom-state-cell.custom-rejected {
  background-color: #ed4f2c; /* Vermelho para rejeitado */
}

.rowinfo{
  display:inline-flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 0px;
  justify-content: space-between;

  }
  

/* Estilos responsivos */
@media (max-width: 768px) {
  .custom-state-cell {
    font-size: 12px; /* Fonte reduzida */
    width: 80px; /* Largura um pouco menor */
    height: 35px; /* Altura reduzida */
    padding: 2px 4px; /* Ajuste de padding */
  }
  .photosEvent{
    flex-direction: column;
   
  }
  .morePhotos{
    margin-left: 10px;
    margin-top: 1rem;
  
  }
  .photoColumn{
    padding:10px;
  }
  .bottomRowInfoClient {

    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .bottomRowInfoClient2 {
    margin-top: 0rem; 
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .bottomRowEdit {
    margin-top: 0rem; 
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
  }
  .clientInfoEditButton {
    width: 15rem;
    height: 1.9rem;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
    line-height: clamp(0.9rem, 2vw, 1rem);
    margin-inline: 8px;
    margin-bottom:0.2rem ;
}
.clientInfoCancelButton {
  width: 15rem;
  height: 1.9rem;
  font-size: clamp(0.75rem, 2vw, 0.875rem);
  line-height: clamp(0.9rem, 2vw, 1rem);
  margin-inline: 8px;
}
.clientInfoCancelButton2 {
  width: 7rem;
  height: 2.1rem;
  font-size: clamp(0.75rem, 2vw, 0.875rem);
  line-height: clamp(0.9rem, 2vw, 1rem);
  margin-inline: 8px;
}
.whiteEditInfoCard2 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 34%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 0rem; 
}

.whiteInfoCard2 {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height:14.5rem;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left:0rem;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  position: relative;
}

.imgClientCard {
  width: 8.3125rem;
  height: 8.3125rem;
  margin: 1%;
  border-radius: 0.9375rem;
  flex-shrink: 0;
  position: relative;

}
.infoClientTitleCard {
  color: rgba(15, 17, 17, 0.60);
  font-family: 'Montserrat';
  font-size: 0.8rem; 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(0.75rem, 1.5vw, 0.875rem); 
  letter-spacing: 0.04rem;
}
.infoClientName {
  color: #044535;
  font-family: 'Montserrat';
  font-size: 0.8rem; 
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem);
}
.infoEventText {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: clamp(1.25rem, 2.5vw, 1.375rem); 
  margin-top: 0.625rem;
}

.whiteBigInfoCard {
  border-radius: 0.9375rem; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15) !important; 
  width: 95%;
  height: 60%;
  flex-shrink: 0;
  margin-top: 1.25rem; 
  margin-left: 0rem;
  margin-bottom: 2.2rem;
}
.addProfilePhoto {
  width: 8rem;
  height: 8rem;
  flex-shrink: 0;
  border-radius: 0.625rem; 
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}

.aproveBtn {
  border-radius: 0.5rem; 
  background: #13BB56;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 5.8125rem; 
  height: 2.5rem; 
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  line-height: clamp(0.75rem, 2vw, 0.875rem); 
  margin-inline: 1.25rem; 
  border:none;
  cursor: pointer;
}
.cancelRedBtn {
  color: #ED4F2C;
  text-align: center;
  text-transform: none;
  font-family: "Montserrat";
  font-size: clamp(0.875rem, 2vw, 1rem); 
  font-style: normal;
  font-weight: 700;
  flex-shrink: 0;
  line-height: clamp(0.75rem, 2vw, 0.875rem); 
  border-radius: 0.5rem; 
  border: 0.0625rem solid #ED4F2C; 
  background: #FFF;
  box-shadow: 0px 0.0625rem 0.125rem 0px rgba(30, 30, 30, 0.15); 
  width: 5.8125rem!important; 
  height: 2.5rem; 
  margin-inline: 1.25rem; 
  cursor: pointer;
}
.btnsrow2admin {
  flex-direction: row;
  margin-top: 0.625rem;
  margin-left: 0rem; 
  margin-right: 0rem;
  display: flex;
}
.btnsrowadmin {
  flex-direction: row;
  margin-top: 0.625rem;
  margin-left: 0rem; 
  margin-right: 0rem;
  display: flex;
}
.blackTextTitleDashboardEvents {
  color: #0F1111;
  font-family: 'Montserrat';
  font-size: clamp(1rem, 2vw, 1.25rem); 
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 1.5rem; 
  align-self: flex-start;
}
.badgeContainer {
  position: absolute;
  top: 4px; 
  right: 18px; 
}
.eventsrow {
  flex-direction: row;
  margin-top: 0;
  margin-left: 0rem; 
  margin-bottom: 1rem;
  display: flex;
  width: auto;
}

.centerpagescroll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  margin-top:5rem;
  overflow: scroll;
  
}
.mapStyleStats{
  margin-left: 0rem!important;
  width:90%;
  height:27rem
}
.activeText {
  font-size:12px;
}
.rowinfo{
flex-direction: column;
margin-top: 10px;
margin-left: 20px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
}
.infoClientText{
  font-size: 0.7rem;
}
}
@media (max-width: 768px) {
  
  .input-container2{
    width: 80%;
  }
  .input-container2 input {
    font-size: 0.8rem; /* Texto menor */
    line-height: 1rem;
  }

  .input-container2 label {
    font-size: 0.8rem;
    left: 0.5rem;
    top: 0.2rem;
  }

  .input-container2 input:focus + label,
  .input-container2 input:not(:placeholder-shown) + label {
    bottom: 0.2rem;
    font-size: 0.7rem;
  }
}

html .widget-3m7gBZ:not(#\\9):not(#\\9):not(#\\9) {
  border: none !important;
}
